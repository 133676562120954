import React, { useState } from "react"
import api from "../utils/api"
import { Alert, Container, Row, Col, Form, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"
import { NavbarContext } from "../context/navbar"

function RegistrationForm({
  thankYouModal,
  openThankYouModal,
  closeThankYouModal,
}) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [profession, setProfession] = useState("Doctor")
  const [registrationNo, setRegistrationNo] = useState("")

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setError("")
    const user = {
      firstName,
      lastName,
      profession,
      registrationNo,
      email,
      password,
      confirm,
    }
    if (
      !firstName ||
      !lastName ||
      !profession ||
      !email ||
      !password ||
      !confirm
    ) {
      setIsLoading(false)
      return setError("Missing required(*) fields.")
    }
    if (profession != "Med Reps or Sales" && !registrationNo) {
      setIsLoading(false)
      return setError("Registration number is required.")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setIsLoading(false)
      return setError("Invalid email.")
    }
    if (password !== confirm) {
      setIsLoading(false)
      return setError("Password and confirm password do not match.")
    } else {
      try {
        await api().post(`/users/register`, { ...user })
        openThankYouModal()
        setIsLoading(false)
        navigate("/")
      } catch (error) {
        const message = error.response.data.message
        setIsLoading(false)
        setError(message)
      }
    }
  }

  return (
    <LoadingOverlay active={isLoading} spinner text="Registering...">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div style={{ margin: "auto" }}>
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <h1 className="text-center">Register</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="profession" className="mt-2">
              <Form.Label column sm="2">
                Profession*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  value={profession}
                  onChange={e => setProfession(e.target.value)}
                >
                  <option>Doctor</option>
                  <option>Nurse</option>
                  <option>Pharmacist</option>
                  <option>Pharmaceutical Technologist</option>
                  <option>Clinical Officer</option>
                  <option>Med Reps or Sales</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="registrationNo" className="mt-2">
              <Form.Label column sm="2">
                Registration Number
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="PPB No. or KMPDB No. or NCK No."
                  value={registrationNo}
                  onChange={e => setRegistrationNo(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Row
              className="mt-2"
              style={{ textAlign: "right" }}
            >
              <Col>
                <Button variant="primary" type="submit">
                  Register
                </Button>
                <p
                  css={`
                    text-align: center;
                  `}
                >
                  Using this platform means you agree to the{" "}
                  <a
                    href="https://www.patameds.com/terms_of_use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and condition
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </LoadingOverlay>
  )
}

export default function UserSignUp() {
  return (
    <NavbarContext.Consumer>
      {context => (
        <React.Fragment>
          <div className="vh-100 d-flex flex-column ">
            <Container style={{ marginTop: "100px" }}>
              <RegistrationForm
                thankYouModal={context?.thankYouModal}
                openThankYouModal={context?.openThankYouModal}
                closeThankYouModal={context?.closeThankYouModal}
              />
            </Container>
          </div>
        </React.Fragment>
      )}
    </NavbarContext.Consumer>
  )
}
